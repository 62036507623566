import React from 'react';

const AnimatedLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="auto"
    viewBox="0 0 1500 350"
    className="logo"
    {...props}
  >
    <g id="axes" transform="translate(0, 0) scale(0.8)">
      <g id="hexagon">
        <line className="logo-line" x1={22.418182} y1={174} x2={98.509091} y2={41.730545} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} />
        <line className="logo-line" x1={98.509091} y1={41.730545} x2={250.690909} y2={41.730545} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} />
        <line className="logo-line" x1={250.690909} y1={41.730545} x2={326.781818} y2={173.52} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} />
        <line className="logo-line" x1={326.781818} y1={173.52} x2={250.690909} y2={305.309455} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} />
        <line className="logo-line" x1={250.690909} y1={305.309455} x2={98.509091} y2={305.309455} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} />
        <line className="logo-line" x1={98.509091} y1={305.309455} x2={22.418182} y2={173.52} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} />
      </g>
      <g id="line">
        <line className="logo-line" x1={137.945761} y1={237.005143} x2={211.254239} y2={110.034857} style={{ fill: "none", stroke: "white", strokeWidth: 15 }} opacity={0}>
          <animate attributeName="opacity" from={0} to={1} dur="0.5s" begin="2.0s" fill="freeze" />
          <animate attributeName="x2" from={137.945761} to={211.254239} dur="1s" begin="2.0s" fill="freeze" />
          <animate attributeName="y2" from={237.005143} to={110.034857} dur="1s" begin="2.0s" fill="freeze" />
        </line>
        <circle className="logo-circle" cx={137.945761} cy={237.005143} r={23} fill="white" opacity={0}>
          <animate attributeName="opacity" from={0} to={1} dur="1.5s" begin="0.5s" fill="freeze" />
        </circle>
        <circle className="logo-circle" cx={211.254239} cy={110.034857} r={23} fill="white" opacity={0}>
          <animate attributeName="opacity" from={0} to={1} dur="1.5s" begin="0.5s" fill="freeze" />
        </circle>
      </g>
      <text x="950" y="200" className="logo-text" textAnchor="middle" dominantBaseline="middle">
        {"Bindbridge"}
      </text>
    </g>
  </svg>
);

export default AnimatedLogo;

import React from 'react';
import AnimatedLogo from '../components/AnimatedLogo'; 
import { Mail } from 'lucide-react';

export default function HomePage() {
  return (
    <div className="logo-container">
      <div className="logo-wrapper">
        <AnimatedLogo className="logo" />
      </div>
      <div className="email-wrapper">
        <a
          href="mailto:info@bindbridge.com"
          className="email-button"
          aria-label="Contact us via email"
        >
          <Mail className="mail-icon" />
        </a>
      </div>
      <span className="logo-text">Bindbridge</span> {/* Text for logo */}
    </div>
  );
}
